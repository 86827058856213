import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { ReactNode, useRef } from "react"
import { ShareButtons } from "./ShareButtons"

const InviteModal = ({
  children,
  url = "https://spava.club/",
}: {
  children: ReactNode
  url?: string
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg">Spava is more craic with friends</AlertDialogHeader>

            <AlertDialogBody>
              <Text mb={5}>Refer a musical athlete:</Text>
              <ShareButtons responsive={false} link={url} size={["md", "lg"]} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default InviteModal
