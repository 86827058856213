import { Button, ButtonProps, Grid, GridItem, GridProps, Text, useToast } from "@chakra-ui/react"
import { RiChat3Fill, RiFileCopyFill, RiShareForwardFill } from "react-icons/ri"
import { SiWhatsapp } from "react-icons/si"

type ButtonSizeProp = ButtonProps["size"]

export const ShareButtons = ({
  link,
  size,
  responsive,
  ...rest
}: {
  link: string
  size: ButtonSizeProp
  responsive: boolean
} & GridProps) => {
  const toast = useToast()
  const encoded = encodeURIComponent(link)
  const hasSharing = !!navigator.share

  return (
    <Grid
      templateColumns={responsive ? ["repeat(2, 1fr)", "repeat(3, 1fr)"] : "repeat(1, 1fr)"}
      gap={2}
      {...rest}
    >
      <Button
        w="full"
        size={size}
        leftIcon={<SiWhatsapp />}
        as="a"
        target="blank"
        href={`https://wa.me/?text=${encoded}`}
      >
        <Text>WhatsApp</Text>
      </Button>
      <Button
        w="full"
        size={size}
        leftIcon={<RiChat3Fill />}
        as="a"
        target="blank"
        href={`sms:&body=${encoded}`}
      >
        <Text>Message</Text>
      </Button>

      <GridItem colSpan={responsive ? [2, 1] : 1}>
        {hasSharing ? (
          <Button
            w="full"
            size={size}
            leftIcon={<RiShareForwardFill />}
            onClick={() => navigator.share({ text: link })}
          >
            More...
          </Button>
        ) : (
          <Button
            w="full"
            size={size}
            leftIcon={<RiFileCopyFill />}
            onClick={async () => {
              await navigator.clipboard.writeText(link)
              toast({ status: "success", title: "copied!", description: link })
            }}
          >
            <Text>Copy Link</Text>
          </Button>
        )}
      </GridItem>
    </Grid>
  )
}
