import { Role } from "types"
import { SerializedActivity } from "./users/queries/getActivity"

export const shouldSync = (lastSync: Date | number | null, seconds: number = 300) =>
  !lastSync || new Date().getTime() - new Date(lastSync).getTime() > seconds * 1000

export const elapsedTimeString = (seconds: number) => {
  const days = Math.floor(seconds / 86400)
  const time = new Date(1000 * seconds).toISOString().slice(11, 19).replace(/^00:/i, "")

  return `${days > 0 ? `${days} days, ` : ""}${time}`
}

export const elapsedTimeStringWithLetters = (seconds: number, includeSeconds: boolean = true) => {
  const days = Math.floor(seconds / 86400)

  const time = new Date(1000 * seconds).toISOString().slice(11, 19)

  const timeArray = time.split(":")

  const hoursString = timeArray[0] === "00" ? "" : `${timeArray[0]}h`
  const daysString = days > 0 ? `${days}d` : ""
  return `${daysString}${hoursString}${timeArray[1]}m${includeSeconds ? timeArray[2] + "s" : ""}`
}

export const timeDiffString = (time: Date, reference: Date) => {
  const diff = time.getTime() - reference.getTime()
  if (diff <= 0) {
    return "start"
  }
  return elapsedTimeStringWithLetters(Math.floor(diff / 1000))
}

export const hasPlaylistScopes = (scopes: string[]) => scopes.includes("playlist-modify-public")

// export const isAdmin = (userId: number) => [1, 2].includes(userId)
export const isAdmin = (role: Role) => role === "STAFF"

export const defaultProfileImage =
  "https://d3nn82uaxijpm6.cloudfront.net/assets/avatar/athlete/large-800a7033cc92b2a5548399e26b1ef42414dd1a9cb13b99454222d38d58fd28ef.png"
export type PlaysT = SerializedActivity["trackPlays"]

export const getMapImage = ({ mapImages }: SerializedActivity, small: boolean = true) =>
  mapImages?.[small ? 0 : (mapImages as any)?.length - 1]?.url as string | undefined
